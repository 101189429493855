.container {
  display: flex;
  padding: 15px;
  flex-direction: column;
}
.heading-container_mobile {
  display: none;
}
.wrapper {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 20px;
  gap: 25px;
  display: flex;
  align-items: center;

  .section-header {
    font-size: 16px;
    font-weight: bolder;
    color: var(--primary);
  }

  .screen-heading {
    gap: 10px;
    flex: 1;
    height: 100%;
    display: flex;
    p {
      display: flex;
      height: 100%;
      align-items: center;
      border-left: 1px solid #d1d1d1;
      color: var(--primary);
      padding: 0 20px;
      // font-size: 12px;
    }
    p:last-child {
      color: #afafaf;
    }
  }
  .menu-hamburger_mobile {
    display: none;
  }
  .search-bar {
    background-color: #fafafa;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex: 0.5;

    img {
      margin-left: 15px;
    }
    input {
      width: 0;
      border: none;
      background-color: transparent;
      font-size: 18px;
      padding: 10px;
      flex: 1;
    }
  }
  .action-button {
    width: 24px;
    height: 24px;
    cursor: pointer;
    border-radius: 100%;

    &:hover {
      background-color: #fafafa;
    }
  }
  .profile-pic {
    width: 50px;
    height: 50px;
    background-color: rgb(231, 231, 231);
    border-radius: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 0;
  }

  .wrapper {
    border-radius: 0;
    padding: 10px;
    gap: 10px;

    .section-header {
      display: none;
    }
    .screen-heading {
      display: none;
    }
    .menu-hamburger_mobile {
      display: block;
      width: 32px;
      aspect-ratio: 1/1;
    }
    .search-bar {
      flex: 1;
      input {
        font-size: 14px;
      }
    }
    .profile-pic {
      width: 40px;
      height: 40px;
    }
  }
  .heading-container_mobile {
    display: flex;
    background: #ffffff;
    padding: 10px 20px;
    align-items: center;
    .section-heading_mobile {
      font-size: 14px;
      color: #007abe;
    }
    p:before {
      content: ">";
      padding: 0 5px;
    }
    p {
      display: flex;
      height: 100%;
      align-items: center;
      color: var(--primary);
      // font-size: 12px;
      font-weight: 400;
    }
    p:last-child {
      color: #afafaf;
    }
  }
}

@media only screen and (max-device-width: 375px) {
  .heading-container_mobile {
    font-size: 10.5px;
  }
}

.login-name {
  display: flex;
  flex-direction: column;
}
.popup {
  position: absolute;
  top: 92px; /* Adjust based on your layout */
  right: 14px; /* Adjust based on your layout */
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.popup p {
  margin: 0 0 10px 0;
  font-size: 14px;
}

.remove-popup {
  cursor: pointer;
}

.popup-first {
  min-width: 300px;
  min-height: 80px;
  display: flex;
  justify-content: space-between;
}

.popup button {
  padding: 5px 12px;
  background-color: #007abe;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
}
